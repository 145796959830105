import React, { useState, useEffect, useContext } from 'react';
import { ConfigContext } from "../../Context/ConfigContext";
import axios from 'axios';
import Swal from 'sweetalert2';
import PageTitle from "../../Components/PageTitle";
import { NoRecords } from "../../Components/Shimmer";

const SupplierManagement = () => {
  const { apiURL, gdcURL, token, headers } = useContext(ConfigContext);
  const [suppliers, setSuppliers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [totalPages, setTotalPages] = useState(0);

  useEffect(() => {
    fetchSuppliers();
  }, [page, limit]);

  const fetchSuppliers = async () => {
    try {
      const response = await axios.get(`${gdcURL}Suppliers/GetSuppliers`, {
        params: { limit:limit, page:page },
        headers,
      });
      if (response.data) {
        setSuppliers(response.data.data.map(supplier => ({
          ...supplier,
          solrTransferStatus: supplier.DATA_TRANSFER_STATUS || 0
        })));
        setTotalRecords(response.data.data.length);
        setTotalPages(Math.ceil(response.data.data.length / limit));
      } else {
        Swal.fire({
          title: "<strong>Error</strong>",
          html: response.data.message,
          icon: "error",
        });
      }
    } catch (error) {
      console.error('Error fetching suppliers:', error);
      Swal.fire({
        title: "<strong>Error</strong>",
        html: error.message,
        icon: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleDownload = async (supplierId, currentStatus) => {
    console.log('handleDownload called with:', supplierId, currentStatus);
    
    if (currentStatus === 2) {
      // If LIST_READY is 2, initiate file download
      window.location.href = `${gdcURL}Gen/DownloadFile/${supplierId}.csv`;
      return;
    }
    
    // Only proceed if the status is 0 or 3
    if (currentStatus !== 0 && currentStatus !== 3) {
      console.log('Exiting handleDownload: status is not 0 or 3');
      return;
    }

    try {
      console.log('Sending request to:', `${gdcURL}Gen/DownloadPartListByBrand`);
      const response = await axios.post(
        `${gdcURL}Gen/DownloadPartListByBrand`, 
        { sup_id: supplierId },
        { headers }
      );
      
      console.log('Response received:', response.data);
      
      if (response.data.success) {
        Swal.fire({
          title: "Success",
          html: "Part list generation started. You will be notified when it's ready for download.",
          icon: "success",
        });
        // Update the supplier's LIST_READY status in the UI
        updateSupplierStatus(supplierId, 1);
      } else {
        throw new Error(response.data.message || "Failed to start part list generation");
      }
    } catch (error) {
      console.error('Error in handleDownload:', error);
      Swal.fire({
        title: "Error",
        html: error.message || "Failed to start part list generation",
        icon: "error",
      });
      // Update the supplier's LIST_READY status to 3 (Failed) in the UI
      updateSupplierStatus(supplierId, 3);
    }
  };

  // Helper function to update supplier status in the UI
  const updateSupplierStatus = (supplierId, newStatus) => {
    setSuppliers(prevSuppliers => 
      prevSuppliers.map(supplier => 
        supplier.SUP_ID === supplierId ? { ...supplier, LIST_READY: newStatus } : supplier
      )
    );
  };

  const handlePrev = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  const handleNext = () => {
    if (page < totalPages) {
      setPage(page + 1);
    }
  };

  const handlePageChange = (e) => {
    setPage(parseInt(e.target.value, 10));
  };

  const handleStartSolrTransfer = async (supplierId) => {
    try {
      // Update UI to show transfer is starting
      updateSupplierSolrStatus(supplierId, 1);

      const response = await axios.post(
        `${gdcURL}Gen/StartSolrDataTransfer`,
        { sup_id: supplierId },
        { headers }
      );
      
      if (response.data.success) {
        Swal.fire({
          title: "Success",
          html: "Solr data transfer started. This process may take some time.",
          icon: "success",
        });
        // Update UI to show transfer is in progress
        updateSupplierSolrStatus(supplierId, 1);
      } else {
        throw new Error(response.data.message || "Failed to start Solr data transfer");
      }
    } catch (error) {
      console.error('Error in handleStartSolrTransfer:', error);
      Swal.fire({
        title: "Error",
        html: error.message || "Failed to start Solr data transfer",
        icon: "error",
      });
      // Update UI to show transfer failed
      updateSupplierSolrStatus(supplierId, 3);
    }
  };

  // Helper function to update supplier Solr transfer status in the UI
  const updateSupplierSolrStatus = (supplierId, newStatus) => {
    setSuppliers(prevSuppliers => 
      prevSuppliers.map(supplier => 
        supplier.SUP_ID === supplierId ? { ...supplier, solrTransferStatus: newStatus } : supplier
      )
    );
  };

  const handleCheckboxChange = async (supplierId, currentStatus) => {
    const newStatus = currentStatus === 1 ? 0 : 1;
    try {
      const response = await axios.post(
        `${gdcURL}Suppliers/UpdateSupplierStatus`,
        { sup_id: supplierId, status: newStatus },
        { headers }
      );

      if (response.data.success) {
        // Update the supplier's status in the UI
        setSuppliers(prevSuppliers =>
          prevSuppliers.map(supplier =>
            supplier.SUP_ID === supplierId ? { ...supplier, SUP_STATUS: newStatus } : supplier
          )
        );
      } else {
        throw new Error(response.data.message || "Failed to update supplier status");
      }
    } catch (error) {
      console.error('Error updating supplier status:', error);
      Swal.fire({
        title: "Error",
        html: error.message || "Failed to update supplier status",
        icon: "error",
      });
    }
  };

  return (
    <div className="main-content">
      <div className="page-content">
        <div className="container-fluid">
          <br />
          <PageTitle title="Supplier Management" primary="Home" />
          <div className="row">
            <div className="col-lg-12 col-sm-12">
              <div className="card">
                <div className="card-header align-items-center d-flex">
                  <h4 className="card-title mb-0 flex-grow-1">Supplier Management List</h4>
                </div>
                <div className="card-body">
                  <div className="table-responsive">
                    <table className="table table-striped text-center">
                      <thead>

                      
                        <tr>
                          <th scope="col">Active</th>
                          <th scope="col">Supplier Logo</th>
                          <th scope="col">Supplier Name</th>
                          <th scope="col">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {loading ? (
                          <tr><td colSpan="5">Loading...</td></tr>
                        ) : suppliers.length === 0 ? (
                          <tr><td colSpan="5"><NoRecords /></td></tr>
                        ) : (
                          suppliers.map((supplier) => (
                            <tr key={supplier.SUP_ID}>
                              <td width="5%">
                                <input
                                  type="checkbox"
                                  checked={supplier.SUP_STATUS === 1}
                                  onChange={() => handleCheckboxChange(supplier.SUP_ID, supplier.SUP_STATUS)}
                                />
                              </td>
                              <td width="10%">
                                <img src={`${gdcURL}sup-logo/${supplier.SUP_LOGO_NAME}`} alt={supplier.SUP_BRAND} width="50" height="50" />
                              </td>
                              <td width="60%">{supplier.SUP_BRAND}</td>
                              <td width="30%">
                                <button
                                  className={`btn btn-sm ${supplier.LIST_READY === 2 ? 'btn-success' : 'btn-primary'} me-2`}
                                  onClick={() => handleDownload(supplier.SUP_ID, supplier.LIST_READY)}
                                  disabled={supplier.LIST_READY === 1}
                                >
                                  {supplier.LIST_READY === 0 && "Generate CSV"}
                                  {supplier.LIST_READY === 1 && "Generating..."}
                                  {supplier.LIST_READY === 2 && "Download CSV"}
                                  {supplier.LIST_READY === 3 && "Failed Try Again"}
                                </button>
                                <button
                                  className={`btn btn-sm ${supplier.solrTransferStatus === 2 ? 'btn-success' : 'btn-info'}`}
                                  onClick={() => {
                                    if(supplier.solrTransferStatus === 0 || supplier.solrTransferStatus === 3){
                                      handleStartSolrTransfer(supplier.SUP_ID)
                                    }
                                  }}
                                  disabled={supplier.solrTransferStatus === 1}
                                >
                                  {supplier.solrTransferStatus === 0 && "Start Solr Transfer"}
                                  {supplier.solrTransferStatus === 1 && "Transferring..."}
                                  {supplier.solrTransferStatus === 2 && "Transfer Complete"}
                                  {supplier.solrTransferStatus === 3 && "Transfer Failed"}
                                </button>
                              </td>
                            </tr>
                          ))
                        )}
                      </tbody>
                      <tfoot className="table-light">
                        <tr>
                          <th colSpan="5">
                            <div className="d-flex justify-content-between">
                              <button
                                disabled={page === 1}
                                type="button"
                                onClick={handlePrev}
                                className="btn btn-warning btn-label waves-effect waves-light"
                              >
                                <i className="ri-arrow-left-line label-icon align-middle fs-16 me-2" />
                                Previous
                              </button>
                              <div className="col-md-4" style={{ display: "flex", alignItems: "center" }}>
                                <small>
                                  Total Records: {totalRecords} | Total Pages: {totalPages} | Current Page: {page}
                                </small>
                              </div>
                              <div className="col-md-2">
                                <select
                                  className="form-select"
                                  value={page}
                                  onChange={handlePageChange}
                                >
                                  {Array.from({ length: totalPages }, (_, i) => (
                                    <option key={i} value={i + 1}>
                                      Page {i + 1}
                                    </option>
                                  ))}
                                </select>
                              </div>
                              <button
                                disabled={page === totalPages}
                                type="button"
                                onClick={handleNext}
                                className="btn btn-primary btn-label waves-effect right waves-light"
                              >
                                <i className="ri-arrow-right-line label-icon align-middle fs-16 ms-2" />
                                Next
                              </button>
                            </div>
                          </th>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SupplierManagement;
