import React, { Component } from 'react'
import PageTitle from "../../Components/PageTitle";
import { TextBox, TextArea, SubmitBtn } from "../../Components/InputElements";
import { ConfigContext } from '../../Context/ConfigContext'
import axios from 'axios';
import Swal from 'sweetalert2'
import withRouter from '../../Utils/withRouter';


class GeneralConfiguration extends Component {
    static contextType = ConfigContext;
    constructor(props, context) {
        super(props, context);

        this.state = {


            usd_exchange_rate: '',
          
            status: false
        };
        this.LoadEditInfo();
    }
    componentDidMount() {

    }
    LoadEditInfo = () => {
        const { token } = this.context;
        const headers = {
            'token': `${token}`,
            'Content-Type': 'application/json', // Set the content type to JSON if needed
        };
        const { apiURL } = this.context;
        // console.log("master_course_id: "+ this.props.master_course_id);

        axios.get(`${apiURL}Configuration/GetGeneralConfiguration`, { headers }).then((response) => {

            var row = response.data.config
            

            this.setState({
                usd_exchange_rate: row.usd_exchange_rate ,
               status : false,

            });
        });

    }


    handleInputChange = (event) => {

        const { name, value, files } = event.target;

        this.setState({ [name]: value });


    };

    handleSubmit = (event) => {
        event.preventDefault();
        const { apiURL } = this.context;
        const { token } = this.context;

        //    const formData = new FormData();
        const formData = {
           
            usd_exchange_rate: this.state.usd_exchange_rate,
            
            lang: 'en',

        };
        const headers = { 'token': `${token}`, 'Content-Type': ' application/x-www-form-urlencoded', };
        this.setState({ status: true });
        axios
            .post(`${apiURL}Configuration/UpdateGeneralConfiguration`, formData, { headers })
            .then((response) => {
                console.log('create response : ', response);
this.setState({status : false});
                if (response.data.success === true) {
                    Swal.fire({
                        title: '<strong>Success</strong>',
                        html: response.data.message,
                        icon: 'success'
                    })
                } else {
                    Swal.fire({
                        title: '<strong>Error</strong>',
                        html: response.data.message,
                        icon: 'error'
                    })
                }
                // console.log('API Response:', JSON.stringify(response.data.success));
            })
            .catch((error) => {
                // Handle any errors that occur during the request
                // console.error('API Error:', error);
                Swal.fire({
                    title: <strong>Error</strong>,
                    html: error,
                    icon: 'error'
                })
            });
    }
    render() {

        return (
            <div className="main-content">
                <div className="page-content">
                    <div className="container-fluid">
                        <br />
                        <PageTitle title={`General Configuration`} primary={`Home`} />
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card" >
                                    <div className="card-header align-items-center d-flex">
                                        <h4 className="card-title mb-0 flex-grow-1">Configure USD Exchange Rate</h4>
                                        <div className="avatar-xs flex-shrink-0">

                                        </div>

                                    </div>
                                    <div className="card-body">
                                        <form onSubmit={this.handleSubmit} >
                                            <div className="row gy-4">
                                                <div className="col-xxl-3 col-md-6">
                                                    <TextBox type='number'  id='usd_exchange_rate' name='usd_exchange_rate' label='USD Exchange Rate ' value={this.state.usd_exchange_rate} change={this.handleInputChange} />
                                                </div>
                                                <div className="col-xxl-3 col-md-6"></div>
                                                <div className="col-xxl-3 col-md-6">

                                                    <SubmitBtn icon={` mdi mdi-spin mdi-cog-outline fs-18`} text={`Update Configuration`} type={`primary`} status={this.state.status} />



                                                </div>

                                            </div>


                                        </form>

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        )
    };
}
export default withRouter(GeneralConfiguration);