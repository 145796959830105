import React, { useState, useEffect, useContext } from 'react';
import Select from 'react-select';
import axios from 'axios';
import { ConfigContext } from '../../Context/ConfigContext';
import PageTitle from "../../Components/PageTitle";
import Swal from 'sweetalert2';
import * as XLSX from 'xlsx'; // Import the xlsx library

const PartsDataByModel = () => {
  const { gdcURL } = useContext(ConfigContext);
  const [makes, setMakes] = useState([]);
  const [selectedMake, setSelectedMake] = useState(null);
  const [parts, setParts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [suppliers, setSuppliers] = useState([]);
  const [selectedSuppliers, setSelectedSuppliers] = useState([]);
  const [downloadsList, setDownloadsList] = useState([]); // New state for downloads list

  useEffect(() => {
    // Fetch makes/brands
    axios.get(`${gdcURL}Cars/GetMakes`)
      .then(response => {
        if (response.data.success) {  // Note the spelling change here
          console.log('Makes data:', response.data.data); // Log the data
          setMakes(response.data.data.map(make => ({
            value: make.MFA_ID,
            label: make.MFA_BRAND
          })));
        } else {
          console.error('Error fetching makes:', response.data.message);
        }
      })
      .catch(error => console.error('Error fetching makes:', error));
  }, [gdcURL]);

  useEffect(() => {
    console.log('Makes state updated:', makes);
  }, [makes]);

  useEffect(() => {
    // Fetch suppliers
    axios.get(`${gdcURL}Suppliers/GetSuppliers?lang=en&status=1`)
      .then(response => {
        setSuppliers(response.data.data);
      })
      .catch(error => console.error('Error fetching suppliers:', error));
  }, []);

  useEffect(() => {
    // Fetch the downloads list when the component mounts
    fetchDownloadsList();
  }, []);

  const fetchDownloadsList = () => {
    axios.get(`${gdcURL}Gen/GetPartsByModelDownloadsList`)
      .then(response => {
        if (response.data.success) {
          setDownloadsList(response.data.data);
        } else {
          console.error('Error fetching downloads list:', response.data.message);
        }
      })
      .catch(error => console.error('Error fetching downloads list:', error));
  };

  const handleGetParts = () => {
    if (selectedSuppliers.length === 0) {
      Swal.fire('Please select at least one supplier');
      return;
    }

    setLoading(true); // Start loading

    // Extract MS_IDs from selected models
    
    const supplierIds = selectedSuppliers.join(',');

    axios.get(`${gdcURL}Gen/getPartsByModel?mfa_id=${selectedMake.value}&SUP_ID=${supplierIds}`)
      .then(response => {
        if (response.data.success) {
          fetchDownloadsList();
          Swal.fire('Success', response.data.message);
        } else {
          Swal.fire('Error fetching parts:', response.data.message);
        }
      })
      .catch(error => {
        console.error('Error fetching parts:', error);
        Swal.fire('Error fetching parts:', error.message);
      })
      .finally(() => {
        setLoading(false); // Stop loading
      });
  };


  const handleDownloadExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(parts);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "PartsData");

    // Generate Excel file and trigger download
    XLSX.writeFile(workbook, "PartsData.xlsx");
  };

  const handleCancelJob = (jobId) => {
    if (!jobId) {
      Swal.fire('Job ID is required');
      return;
    }

    axios.post(`${gdcURL}Gen/cancel-job`, { jobId })
      .then(response => {
        if (response.data.success) {
          Swal.fire('Success', response.data.message);
          fetchDownloadsList(); // Refresh the downloads list after cancellation
        } else {
          Swal.fire('Error', response.data.message);
        }
      })
      .catch(error => {
        console.error('Error canceling job:', error);
        Swal.fire('Error canceling job:', error.message);
      });
  };

  return (
    <div className="main-content">
      <div className="page-content">
        <div className="container-fluid">
          <br />
          <PageTitle title="Parts Data By Model" primary="Home" />
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-header align-items-center d-flex">
                  <h4 className="card-title mb-0 flex-grow-1">Select Make and Supplier</h4>
                </div>
                <div className="card-body">
                  <div className="row gy-4">
                    <div className="col-md-4">
                      <label>Make / Brand</label>
                      <Select
                        options={makes}
                        onChange={setSelectedMake}
                        value={selectedMake}
                        placeholder="Select Make / Brand"
                      />
                    </div>
                    <div className="col-md-4">
                      <label>Suppliers</label>
                      <select
                        className='form-control'
                        multiple
                        style={{ minHeight: '250px' }}
                        onChange={(e) => {
                          const selectedOptions = Array.from(e.target.selectedOptions, option => option.value);
                          setSelectedSuppliers(selectedOptions);
                        }}
                      >
                        {suppliers.map((supplier, index) => (
                          <option key={index} value={supplier.SUP_ID}>{supplier.SUP_BRAND}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <button
                    className="btn btn-primary mt-3"
                    onClick={handleGetParts}
                    disabled={loading}
                  >
                    Get Part List
                  </button>
                  <button
                    className="btn btn-secondary mt-3 ms-2"
                    onClick={handleDownloadExcel}
                    disabled={loading || parts.length === 0}
                  >
                    Download Excel
                  </button>
                  <div className="mt-4">
                    {loading ? (
                      <div className="spinner-border text-primary" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    ) : (
                      <>
                        
                        <ul>
                          {parts.map((part, index) => (
                            <li key={`${part.PART_NUMBER}-${index}`}>
                              {part.PART_NAME} - {part.PART_NUMBER} - {part.PART_BRAND} - {part.CAR_BRAND} - {part.MODEL}
                            </li>
                          ))}
                        </ul>
                      </>
                    )}
                  </div>
                  <div className="mt-4">
                    <h5>Downloads List 
                      <button className="btn btn-xs btn-primary ms-2" onClick={fetchDownloadsList}>
                        <i className="bx bx-refresh"></i>
                      </button>
                    </h5>
                    <table className="table">
                      <thead>
                        <tr>
                          <th>Status</th>
                          <th>Download Time</th>
                          <th>Progress</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {downloadsList.map((download, index) => {
                          let statusText;
                          switch (download.FILE_STATUS) {
                            case 1:
                              statusText = 'In Process';
                              break;
                            case 2:
                              statusText = 'Completed';
                              break;
                            case 3:
                              statusText = 'Failed';
                              break;
                            default:
                              statusText = 'Unknown';
                          }
                          return (
                            <tr key={index}>
                              <td>{statusText}</td>
                              <td>{new Date(download.FILES_DOWNLOA_DATETIME).toLocaleString()}</td>
                              <td>{download.FILE_PROGRESS}</td>
                              <td>
                                {download.FILE_STATUS !== 2 && (
                                  <button onClick={() => handleCancelJob(download.FILE_JOB_ID)}>
                                    Cancel
                                  </button>
                                )}
                                <button
                                  onClick={() => {
                                    const downloadUrl = `${gdcURL}Gen/DownloadPartFile/${download.FILE_PATH}`;

                                    // Open the download link in a new tab
                                    const newTab = window.open(downloadUrl, '_blank');

                                    // Optional: Check if the tab was blocked
                                    if (newTab) {
                                      newTab.opener = null; // Security measure to prevent the new tab from being controlled
                                      newTab.focus();
                                    } else {
                                      alert("Please allow pop-ups for this website to download the file.");
                                    }
                                  }}
                                >
                                  Download
                                </button>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PartsDataByModel;


